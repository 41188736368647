<template>
  <div>
    <div class="form-header">
      <div style="text-transform: uppercase">Заявка</div>
      юридического лица (индивидуального предпринимателя), физического лица на
      присоединение энергопринимающих устройств
    </div>
    <div class="form-body">
      <p>
        1. Наименование заявителя: <b>{{ doc.partner.name }}</b>
      </p>
      <div v-if="doc.partner.orgType === 0">
        <p>
          2. Номер записи в Едином государственном реестре юридических лиц
          (номер записи в Едином государственном реестре индивидуальных
          предпринимателей) и дата ее внесения в реестр
          <b>{{ doc.partner.ogrn }}</b> от
          <b>{{ doc.partner.creationDate }}</b>
        </p>
      </div>
      <div v-else-if="doc.partner.orgType === 1">
        <p>
          2. Паспортные данные серия, номер
          <b>{{ doc.partner.passportNum }}</b> выдан (кем, когда)
          <b>{{ doc.partner.passportOrg }}</b>
        </p>
      </div>
      <p>
        3. Место нахождения заявителя, в том числе фактический адрес
        <b>{{ doc.partner.address }}</b>
      </p>
      <p>
        4. В связи с
        <b>{{ reason[(+doc.reason || 1) - 1].text }}</b> просит осуществить
        технологическое присоединение <b>{{ doc.devNames }}</b> расположенных
        <b>{{ doc.address }}</b>
      </p>
      <p>
        5. Количество точек присоединения с указанием технических параметров
        элементов энергопринимающих устройств <b>{{ doc.pointsNum }}</b>
      </p>
      <p>
        6. Максимальная мощность энергопринимающих устройств (присоединяемых и
        ранее присоединенных) <b>{{ doc.maxPower }}</b> составляет кВт при
        напряжении <b>{{ doc.voltage1 }}</b> кВ
      </p>
      <p>
        а) максимальная мощность присоединяемых энергопринимающих устройств
        составляет <b>{{ doc.powerOwened }}</b> кВт при напряжении
        <b>{{ doc.voltage2 }}</b> кВ со следующим распределением по точкам
        присоединения:
      </p>
      <p v-for="p in doc.pointsC" :key="'A' + p._id.toString()">
        точка присоединения <b>{{ p.name }}</b> - <b>{{ p.value }}</b> кВт;
      </p>
      <p>
        б) максимальная мощность ранее присоединенных энергопринимающих
        устройств составляет <b>{{ doc.powerBefore }}</b> кВт при напряжении
        <b>{{ doc.voltage3 }}</b> кВ со следующим распределением по точкам
        присоединения:
      </p>
      <p v-for="p in doc.pointsB" :key="'B' + p._id.toString()">
        точка присоединения <b>{{ p.name }}</b> - <b>{{ p.value }}</b> кВт;
      </p>
      <p>
        7. Количество и мощность присоединяемых к сети трансформаторов
        <b>{{ doc.transNum }}</b> кВА.
      </p>
      <p>
        8. Количество и мощность генераторов <b>{{ doc.genNum }}</b
        >.
      </p>
      <p>9. Заявляемая категория надежности энергопринимающих устройств:</p>
      <p>
        I категория <b>{{ doc.level1 }}</b> кВт;
      </p>
      <p>
        II категория <b>{{ doc.level2 }}</b> кВт;
      </p>
      <p>
        III категория <b>{{ doc.level3 }}</b> кВт;
      </p>
      <p>
        10. Заявляемый характер нагрузки (для генераторов – возможная скорость
        набора или снижения нагрузки) и наличие нагрузок, искажающих форму
        кривой электрического тока и вызывающих несимметрию напряжения в точках
        присоединения <b>{{ doc.loadNatureFull }}</b>
      </p>
      <p>
        11. Величина и обоснование величины технологического минимума (для
        генераторов) <b>{{ doc.generator }}</b>
      </p>
      <p>
        12. Необходимость наличия технологической и (или) аварийной брони
        <b>{{ doc.armor }}</b>
      </p>
      <p>
        Величина и обоснование технологической и аварийной брони
        <b>{{ doc.armorValue }}</b>
      </p>
      <p>
        13. Сроки проектирования и поэтапного введения в эксплуатацию объекта (в
        том числе по этапам и очередям), планируемое поэтапное распределение
        максимальной мощности: <b>{{ doc.dates }}</b>
      </p>
      <p>
        14. Гарантирующий поставщик (энергосбытовая организация), с которым
        планируется заключение договора энергоснабжения (купли-продажи
        электрической энергии (мощности):
        <b>{{ 'ПАО "Красноярскэнергосбыт"' }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import { CONNECTION } from "../../../utils/mainConsts";

export default {
  props: ["doc"],
  data: () => ({
    reason: CONNECTION.reason,
    loadNature: CONNECTION.loadNature,
    status: CONNECTION.status,
    reliability: CONNECTION.reliability,
  }),
};
</script>

<style scoped>
.form-header {
  padding: 20px 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.form-body {
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  color: black;
}
</style>
