<template>
  <v-dialog
    v-model="itemWindow"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card v-if="Object.keys(doc).length > 0">
      <v-card-text>
        <v-btn outlined small dark color="red" @click="onClose">
          <v-icon>
            mdi-window-close
          </v-icon>
          Закрыть окно
        </v-btn>
        <v-btn outlined small dark color="blue" @click.prevent="printText">
          <v-icon>
            mdi-printer
          </v-icon>
          Распечатать заявку
        </v-btn>

        <div class="myDivToPrint">
          <temp-form v-if="doc.type === 1" :doc="doc" />
          <simple-form v-if="doc.type === 2" :doc="doc" />
          <simple-150-form v-if="doc.type === 3" :doc="doc" />
          <person-15-form v-if="doc.type === 4" :doc="doc" />
          <v-card v-if="role > 1">
            <v-card-title>Контактная информация</v-card-title>
            <v-card-subtitle>Контрагента:</v-card-subtitle>
            <v-card-text>
              Телефон: {{ doc.partner.telephone }}<br />
              E-mail: {{ doc.partner.email }}
            </v-card-text>
            <v-card-text></v-card-text>
          </v-card>
          <v-card v-if="role > 1" class="show-on-print">
            <v-card-title>Подпись</v-card-title>
            <v-card-text>
              Заявление подано электронно через портал sibseti.su
            </v-card-text>
            <v-card-text></v-card-text>
          </v-card>
        </div>
        <v-card class="mx-auto" tile style="margin: 20px 0">
          <v-card-title>Приложенные файлы</v-card-title>
          <v-spacer></v-spacer>
          <v-list link dense>
            <v-list-item
              v-for="(item, i) in doc.files"
              :key="i"
              :href="
                path +
                  'images/' +
                  item.createdBy._id.toString() +
                  '/' +
                  item.fileName
              "
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  item.fileName.slice(37)
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon>mdi-information</v-icon>
                    </span>
                  </template>
                  <span>{{ fileTip[item.tag] }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <comments-field
          v-if="role != 2"
          :messages="comments"
          document="connection"
          :documentId="doc._id"
          :partner="doc.partner._id"
          @toUpdate="updateComments"
        />
        <v-card class="mx-auto" tile style="margin: 20px 0" v-if="role != 2">
          <v-card-title>Документы на основании заявки</v-card-title>
          <v-card-subtitle
            >Документы сформированные сетевой организацией на основании
            заявки</v-card-subtitle
          >
          <v-card-text>
            <v-list link dense>
              <v-list-item
                v-for="(item, i) in doc.resultFiles"
                :key="i"
                :href="
                  path +
                    'images/' +
                    item.createdBy._id.toString() +
                    '/' +
                    item.fileName
                "
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.tag }}</v-list-item-title>
                  {{ item.subType }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon>mdi-information</v-icon>
                      </span>
                    </template>
                    <span>{{ fileTip[item.tag] }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <files-loader :doc="doc" v-if="role >= 5" />
          </v-card-text>
        </v-card>

        <v-card class="mx-auto" tile style="margin: 20px 0" v-if="role >= 5">
          <v-card-title>Управление заявкой</v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col> </v-col>
              <v-col>
                <v-text-field
                  v-model="doc.enterNum"
                  outlined
                  dense
                  label="Вх. номер"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="status"
                  v-model="doc.status"
                  label="Статус"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="doc.bailee.name"
                  outlined
                  dense
                  label="Ответственное лицо"
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="onSave">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="red" @click="onClose">
          <v-icon left>
            mdi-close-box-outline
          </v-icon>
          Закрыть окно
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CONNECTION } from '../../utils/mainConsts';
import { sendRequest } from '../../utils/graphql';
import { mapMutations } from 'vuex';

import Person15 from './ConnectionForm/Person15';
import Simple150 from './ConnectionForm/Simple150';
import SimpleForm from './ConnectionForm/Simple';
import TempForm from './ConnectionForm/Temp';

import Commentaries from '../ui/Comments';
import FilesLoader from '../ui/ConnectionFilesLoader';

export default {
  name: 'ConnectionDocument',
  components: {
    'person-15-form': Person15,
    'simple-150-form': Simple150,
    'simple-form': SimpleForm,
    'temp-form': TempForm,
    'comments-field': Commentaries,
    'files-loader': FilesLoader
  },
  props: {
    itemWindow: Boolean,
    doc: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    reason: CONNECTION.reason,
    loadNature: CONNECTION.loadNature,
    status: CONNECTION.status,
    path: '',
    fileTip: {
      plan:
        'План расположения энергопринимающего устройства присоединяемого к сетям сетевой организации',
      energySchema:
        'Однолинейная схема электрических сетей присоединяемых к электрическим сетям сетевой организации, номинальный класс напряжения которых составляет 35 кВ и выше, с указанием возможности резервирования от собственных источников энергоснабжения (включая резервирование для собственных нужд) и возможности переключения нагрузок (генерации) по внутренним сетям заявителя',
      ownership:
        'Копия документа, подтверждающего право собственности или иное предусмотренное законом основание на объект капитального строительства (нежилое помещение в таком объекте капитального строительства) и (или) земельный участок, на котором расположены (будут располагаться) объекты заявителя, либо право собственности или иное предусмотренное законом основание на энергопринимающие устройства (для заявителей, планирующих осуществить технологическое присоединение энергопринимающих устройств потребителей, расположенных в нежилых помещениях многоквартирных домов или иных объектах капитального строительства, - копия документа, подтверждающего право собственности или иное предусмотренное законом основание на нежилое помещение в таком многоквартирном доме или ином объекте капитального строительства)',
      ukAgreement:
        'Копия документа, подтверждающего согласие организации, осуществляющей управление многоквартирным домом, при наличии у такой организации соответствующих полномочий либо при ее отсутствии или отсутствии у нее полномочий согласие общего собрания владельцев жилых помещений многоквартирного дома на организацию присоединения нежилого помещения отдельными линиями от вводного устройства (вводно-распределительного устройства, главного распределительного щита), установленного на вводе питающей линии сетевой организации в соответствующее здание или его обособленную часть (если для соответствующего нежилого помещения проектом на многоквартирный дом не предусмотрено индивидуальное вводно-распределительное устройство с непосредственным присоединением к питающей линии сетевой организации)',
      misc: 'Прочий документ'
    },
    comments: []
  }),
  computed: {
    role: function() {
      return this.$store.state.role;
    }
  },
  watch: {
    doc: function(value) {
      this.path = process.env.VUE_APP_REST;
    },
    itemWindow: function(value) {
      if (value) {
        this.onShowWindow();
      }
    }
  },
  methods: {
    ...mapMutations(['setMessageData']),
    printText() {
      window.print();
    },
    // При показе окна
    // Подгружаем комментарии
    async onShowWindow() {
      this.updateComments();
    },
    updateComments() {
      const token = this.$store.state.token;
      const query = `query CommentFetchByDocument($id: String, $doc: String) {
        commentFetchByDocument(id: $id, doc: $doc) {
          _id
          message
          createdBy { name }
        }
      }`;
      const variables = {
        id: this.doc._id.toString(),
        doc: 'connection'
      };

      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        this.comments = result.data.commentFetchByDocument;
      });
    },
    // Сохранение
    async onSave() {
      const token = this.$store.state.token;
      const query = `mutation ChangeStatus($id: String!, $status: Int, $bailee: String, $enterNum: String) {
        connectionChangeStatus(id: $id, status: $status, bailee: $bailee, enterNum: $enterNum) {
          _id
        }
      }`;
      const variables = {
        id: this.doc._id.toString(),
        status: this.doc.status,
        bailee: this.$store.state.userId,
        enterNum: this.doc.enterNum
      };

      const result = await sendRequest(token, query, variables);
      // Сообщить о результате
      let message = 'Изменение сохранено';
      if (result.error) {
        message = `Ошибка: ${result.error}`;
      }
      this.setMessageData({
        message,
        type: 'snackbar'
      });
    },
    onClose() {
      this.$emit('onClose');
    },
    async downloadFile(file) {
      const authHeader = 'Bearer ' + this.$store.state.token;
      const options = {
        method: 'GET'
        // headers: {
        //   Authorization: authHeader
        // }
      };
      const res = await fetch(file, options);
      // CHROME
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'drom.csv';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); // afterwards we remove the element again
    }
  }
};
</script>

<style scoped>
.connection-actions {
  padding: 30px;
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
}
.show-on-print {
  display: none;
}
@media print {
  .show-on-print {
    display: block;
  }
  .myDivToPrint {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    z-index: 9999999;
  }
}
</style>
