<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" outlined color="indigo" v-on="on" v-if="role != 2"
        >Новая заявка</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }}: {{ title }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <div v-if="!hideForm">
            <v-radio-group v-model="editedItem.type">
              <v-radio
                label="Заявка юридического лица (индивидуального предпринимателя), физического лица на временное присоединение энергопринимающих устройств"
                :value="1"
              ></v-radio>

              <v-radio
                label="Заявка юридического лица (индивидуального предпринимателя), физического лица на присоединение энергопринимающих устройств"
                :value="2"
              ></v-radio>

              <v-radio
                label="Заявка юридического лица (индивидуального предпринимателя), физического лица на присоединение по одному источнику электроснабжения энергопринимающих устройств с максимальной мощностью до 150 кВт включительно"
                :value="3"
              ></v-radio>

              <v-radio
                v-if="role >= 5 || thisUser.partner.orgType === 1"
                label="Заявка физического лица на присоединение по одному источнику электроснабжения энергопринимающих устройств с максимальной мощностью до 15 кВт включительно (используемых для бытовых и иных нужд, не связанных с осуществлением предпринимательской деятельности)"
                :value="4"
              ></v-radio>
            </v-radio-group>
            <div v-if="editedItem.type < 1">Выбирите тип заявления!</div>
            <div v-else>
              <item-selector2
                v-if="role >= 5"
                :model="editedItem.partner"
                @itemSelected="editedItem.partner = $event"
                :errorMessages="partnerErrors"
                label="Данные клиента"
                tag="partner"
              />
              <v-text-field
                v-model.trim="editedItem.devNames"
                :counter="99"
                @input="$v.editedItem.devNames.$touch()"
                @blur="$v.editedItem.devNames.$touch()"
                :error-messages="devNamesErrors"
                dense
                outlined
                label="Наименование присоединяемых энергопринемающих устройств, объекта"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.pointsNum"
                :counter="199"
                dense
                outlined
                label="Количество точек"
                hint="Количество точек присоединения с указанием технических параметров элементов энергопринимающих устройств (описание существующей сети для присоединения, максимальной мощности (дополнительно или вновь) или (и) планируемых точек присоединения)"
                persistent-hint
              ></v-text-field>
              <v-text-field
                v-model.trim="editedItem.region"
                :counter="99"
                @input="$v.editedItem.region.$touch()"
                @blur="$v.editedItem.region.$touch()"
                :error-messages="regionErrors"
                outlined
                dense
                label="Регион местонахождения энергопринимающего устройства"
              ></v-text-field>
              <v-text-field
                v-model.trim="editedItem.address"
                :counter="199"
                @input="$v.editedItem.address.$touch()"
                @blur="$v.editedItem.address.$touch()"
                :error-messages="addressErrors"
                outlined
                dense
                label="Адрес местонахождения энергопринемающих устройств"
              ></v-text-field>
              <div class="form-area">
                <header>Максимальная мощность суммарная</header>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.maxPower"
                      :counter="9"
                      @input="$v.editedItem.maxPower.$touch()"
                      @blur="$v.editedItem.maxPower.$touch()"
                      :error-messages="maxPowerErrors"
                      outlined
                      dense
                      hint="Присоединяемая + Ранее присоединенная энергопринимающих устройств"
                      label="Максимальная мощность, кВт"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.voltage1"
                      @input="$v.editedItem.voltage1.$touch()"
                      @blur="$v.editedItem.voltage1.$touch()"
                      :error-messages="voltage1Errors"
                      :counter="9"
                      outlined
                      dense
                      label="Уровень напряжения, кВ"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="form-area" v-if="toShow(2, 3, 4)">
                <header>Присоединяемая мощность</header>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.powerOwened"
                      :counter="9"
                      @input="$v.editedItem.powerOwened.$touch()"
                      @blur="$v.editedItem.powerOwened.$touch()"
                      :error-messages="powerOwenedErrors"
                      outlined
                      dense
                      label="Присоединяемая мощность, кВА"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.voltage2"
                      @input="$v.editedItem.voltage2.$touch()"
                      @blur="$v.editedItem.voltage2.$touch()"
                      :error-messages="voltage2Errors"
                      :counter="9"
                      outlined
                      dense
                      label="Уровень напряжения, кВ"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div v-if="toShow(2)">
                  По точкам присоединения
                  <v-btn
                    x-small
                    color="secondary"
                    @click.prevent="
                      editedItem.pointsC.push({ name: '', value: '' })
                    "
                    >Добавить точку</v-btn
                  >:
                  <v-row
                    v-for="(point, index) in editedItem.pointsC"
                    :key="index"
                  >
                    <v-col>
                      <v-text-field
                        v-model.trim="point.name"
                        :counter="99"
                        outlined
                        dense
                        label="Наименование"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model.trim="point.value"
                        :counter="9"
                        outlined
                        dense
                        label="Уровень напряжения, кВт"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="form-area" v-if="toShow(2, 3, 4)">
                <header>Ранее присоединенная мощность</header>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.powerBefore"
                      :counter="9"
                      @input="$v.editedItem.powerBefore.$touch()"
                      @blur="$v.editedItem.powerBefore.$touch()"
                      :error-messages="powerBeforeErrors"
                      outlined
                      dense
                      label="Ранее присоединенная мощность, кВт"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.trim="editedItem.voltage3"
                      @input="$v.editedItem.voltage3.$touch()"
                      @blur="$v.editedItem.voltage3.$touch()"
                      :error-messages="voltage3Errors"
                      :counter="9"
                      outlined
                      dense
                      label="Уровень напряжения, кВ"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div v-if="toShow(2)">
                  По точкам присоединения
                  <v-btn
                    x-small
                    color="secondary"
                    @click.prevent="
                      editedItem.pointsB.push({ name: '', value: '' })
                    "
                    >Добавить точку</v-btn
                  >:
                  <v-row
                    v-for="(point, index) in editedItem.pointsB"
                    :key="index"
                  >
                    <v-col>
                      <v-text-field
                        v-model.trim="point.name"
                        :counter="99"
                        outlined
                        dense
                        label="Наименование"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model.trim="point.value"
                        :counter="9"
                        outlined
                        dense
                        label="Уровень напряжения, кВт"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-text-field
                v-if="toShow(1)"
                v-model.trim="editedItem.tempDates"
                :counter="99"
                @input="$v.editedItem.tempDates.$touch()"
                @blur="$v.editedItem.tempDates.$touch()"
                :error-messages="tempDatesErrors"
                outlined
                dense
                label="Срок электроснабжения по временной схеме"
              ></v-text-field>
              <v-text-field
                v-if="toShow(1)"
                v-model.trim="editedItem.contractInfo"
                :counter="99"
                @input="$v.editedItem.contractInfo.$touch()"
                @blur="$v.editedItem.contractInfo.$touch()"
                :error-messages="contractInfoErrors"
                outlined
                dense
                label="Реквизиты договора на технологическое присоединение"
              ></v-text-field>
              <v-select
                v-if="toShow(5)"
                v-model="editedItem.loadNature"
                @input="$v.editedItem.loadNature.$touch()"
                @blur="$v.editedItem.loadNature.$touch()"
                :error-messages="loadNatureErrors"
                dense
                outlined
                label="Характер нагрузки"
                :items="loadNature"
              />
              <v-text-field
                v-if="toShow(2, 3, 4)"
                v-model.trim="editedItem.loadNatureFull"
                @input="$v.editedItem.loadNatureFull.$touch()"
                @blur="$v.editedItem.loadNatureFull.$touch()"
                :error-messages="loadNatureFullErrors"
                :counter="199"
                outlined
                dense
                persistent-hint
                hint="Заявляемый характер нагрузки (бытовая, промышленная, вид деятельности организации) (для генераторов – возможная скорость набора или снижения нагрузки) и наличие нагрузок, искажающих форму кривой электрического тока и вызывающих несимметрию напряжения в точках присоединения"
                label="Заявляемый характер нагрузки"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.transNum"
                @input="$v.editedItem.transNum.$touch()"
                @blur="$v.editedItem.transNum.$touch()"
                :error-messages="transNumErrors"
                :counter="19"
                outlined
                dense
                persistent-hint
                label="Количество и мощность присоединяемых к сети трансформаторов, кВт"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.genNum"
                @input="$v.editedItem.genNum.$touch()"
                @blur="$v.editedItem.genNum.$touch()"
                :error-messages="genNumErrors"
                :counter="19"
                outlined
                dense
                persistent-hint
                label="Количество и мощность генераторов"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.generator"
                @input="$v.editedItem.generator.$touch()"
                @blur="$v.editedItem.generator.$touch()"
                :error-messages="generatorErrors"
                :counter="199"
                outlined
                dense
                persistent-hint
                hint="Величина и обоснование величины технологического минимума (для генераторов)"
                label="Технологический минимум"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.armor"
                @input="$v.editedItem.armor.$touch()"
                @blur="$v.editedItem.armor.$touch()"
                :error-messages="armorErrors"
                :counter="199"
                outlined
                dense
                persistent-hint
                hint="Необходимость наличия технологической и (или) аварийной брони"
                label="Аварийная броня"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2)"
                v-model.trim="editedItem.armorValue"
                @input="$v.editedItem.armorValue.$touch()"
                @blur="$v.editedItem.armorValue.$touch()"
                :error-messages="armorValueErrors"
                :counter="199"
                outlined
                dense
                persistent-hint
                hint="Величина и обоснование технологической и аварийной брони"
                label="Величина аварийной брони"
              ></v-text-field>
              <v-select
                v-if="toShow(3, 4)"
                v-model="editedItem.reliability"
                @input="$v.editedItem.reliability.$touch()"
                @blur="$v.editedItem.reliability.$touch()"
                :error-messages="reliabilityErrors"
                dense
                outlined
                label="Заявленный уровень надежности"
                :items="reliability"
                hint="Заявленный уровень надежности энергопринимающего устройства"
                persistent-hint
              />
              <div class="form-area" v-if="toShow(2)">
                <header>
                  Заявляемая категория надежности энергопринимающих устройств
                </header>
                <v-row>
                  <v-col
                    ><v-text-field
                      v-model.trim="editedItem.level1"
                      @input="$v.editedItem.level1.$touch()"
                      @blur="$v.editedItem.level1.$touch()"
                      :error-messages="level1Errors"
                      :counter="9"
                      outlined
                      dense
                      label="I Категория, кВт"
                    ></v-text-field
                  ></v-col>
                  <v-col
                    ><v-text-field
                      v-model.trim="editedItem.level2"
                      @input="$v.editedItem.level2.$touch()"
                      @blur="$v.editedItem.level2.$touch()"
                      :error-messages="level2Errors"
                      :counter="9"
                      outlined
                      dense
                      label="II Категория, кВт"
                    ></v-text-field
                  ></v-col>
                  <v-col
                    ><v-text-field
                      v-model.trim="editedItem.level3"
                      @input="$v.editedItem.level3.$touch()"
                      @blur="$v.editedItem.level3.$touch()"
                      :error-messages="level3Errors"
                      :counter="9"
                      outlined
                      dense
                      label="III Категория, кВт"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>
              <v-text-field
                v-if="toShow(2, 3, 4)"
                v-model.trim="editedItem.dates"
                :counter="199"
                @input="$v.editedItem.dates.$touch()"
                @blur="$v.editedItem.dates.$touch()"
                :error-messages="datesErrors"
                outlined
                dense
                hint="Cроки проектирования и поэтапного введения в эксплуатацию энергопринимающего устройства"
                persistent-hint
                label="Cроки проектирования"
              ></v-text-field>
              <v-text-field
                v-if="toShow(2, 3, 4)"
                v-model.trim="editedItem.plans"
                :counter="199"
                @input="$v.editedItem.plans.$touch()"
                @blur="$v.editedItem.plans.$touch()"
                :error-messages="plansErrors"
                outlined
                dense
                hint="Планируемое распределение максимальной мощности, сроки ввода и сведения о категории надежности электроснабжения при вводе энергопринимающих устройств по этапам и очередям"
                persistent-hint
                label="Распределение, сроки и категория надежности"
              ></v-text-field>
              <v-text-field
                v-if="toShow(3)"
                v-model.trim="editedItem.offers"
                :counter="199"
                @input="$v.editedItem.offers.$touch()"
                @blur="$v.editedItem.offers.$touch()"
                :error-messages="offersErrors"
                outlined
                dense
                hint="Предложения по порядку расчетов и условиям рассрочки внесения платы за технологическое присоединение"
                persistent-hint
                label="Предложения по порядку расчетов"
              ></v-text-field>
              <v-select
                v-model="editedItem.reason"
                @input="$v.editedItem.reason.$touch()"
                @blur="$v.editedItem.reason.$touch()"
                :error-messages="reasonErrors"
                dense
                outlined
                label="Цель обращения"
                :items="reason"
              />
              <v-select
                v-model="editedItem.method"
                @input="$v.editedItem.method.$touch()"
                @blur="$v.editedItem.method.$touch()"
                :error-messages="methodErrors"
                dense
                outlined
                label="Способ передачи проекта договора и технических условий"
                :items="method"
              />
              <div class="form-area" style="padding: 25px">
                <header>
                  Прилагаемые файлы (pdf, png, jpeg, jpg: не более 2Мб каждый,
                  не более 10 файлов)
                </header>
                <v-file-input
                  v-if="toShow(2, 3, 4)"
                  v-model="files.plan"
                  dense
                  multiple
                  outlined
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="План расположения"
                  persistent-hint
                  hint="План расположения энергопринимающего устройства присоединяемого к сетям сетевой организации"
                ></v-file-input>
                <v-file-input
                  v-if="toShow(2, 3, 4)"
                  v-model="files.energySchema"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Однолинейная схема"
                  persistent-hint
                  hint="Однолинейная схема электрических сетей присоединяемых к электрическим сетям сетевой организации, номинальный класс напряжения которых составляет 35 кВ и выше, с указанием возможности резервирования от собственных источников энергоснабжения (включая резервирование для собственных нужд) и возможности переключения нагрузок (генерации) по внутренним сетям заявителя"
                ></v-file-input>
                <v-file-input
                  v-model="files.ownership"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Право собственности"
                  persistent-hint
                  hint="Копия документа, подтверждающего право собственности или иное предусмотренное законом основание на объект капитального строительства (нежилое помещение в таком объекте капитального строительства) и (или) земельный участок, на котором расположены (будут располагаться) объекты заявителя, либо право собственности или иное предусмотренное законом основание на энергопринимающие устройства (для заявителей, планирующих осуществить технологическое присоединение энергопринимающих устройств потребителей, расположенных в нежилых помещениях многоквартирных домов или иных объектах капитального строительства, - копия документа, подтверждающего право собственности или иное предусмотренное законом основание на нежилое помещение в таком многоквартирном доме или ином объекте капитального строительства)"
                ></v-file-input>
                <v-file-input
                  v-if="toShow(2, 3, 4)"
                  v-model="files.ukAgreement"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Разрешение управляющей компании"
                  persistent-hint
                  hint="Копия документа, подтверждающего согласие организации, осуществляющей управление многоквартирным домом, при наличии у такой организации соответствующих полномочий либо при ее отсутствии или отсутствии у нее полномочий согласие общего собрания владельцев жилых помещений многоквартирного дома на организацию присоединения нежилого помещения отдельными линиями от вводного устройства (вводно-распределительного устройства, главного распределительного щита), установленного на вводе питающей линии сетевой организации в соответствующее здание или его обособленную часть (если для соответствующего нежилого помещения проектом на многоквартирный дом не предусмотрено индивидуальное вводно-распределительное устройство с непосредственным присоединением к питающей линии сетевой организации)"
                ></v-file-input>
                <v-file-input
                  v-if="toShow(1)"
                  v-model="files.contractInfo"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Информация о реквизитах договора"
                  persistent-hint
                  hint="Информация о реквизитах договора об осуществлении технологического присоединения (за исключением заявителей, энергопринимающие устройства которых являются передвижными и имеют максимальную мощность до 150 кВт включительно)"
                ></v-file-input>
                <v-file-input
                  v-model="files.routes"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Доверенность или иные документы"
                  persistent-hint
                  hint="Доверенность или иные документы, подтверждающие полномочия представителя заявителя, подающего и получающего документы, в случае подачи заявки представителем заявителя, а также документ подтверждающий полномочия руководителя либо лица, уполномоченного на подписание договора"
                ></v-file-input>
                <v-file-input
                  v-model="files.misc"
                  dense
                  outlined
                  multiple
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  prepend-icon="mdi-image"
                  label="Прочий документ"
                  persistent-hint
                ></v-file-input>
              </div>
              <v-checkbox
                v-model="editedItem.personalAgreement"
                @input="$v.editedItem.personalAgreement.$touch()"
                @blur="$v.editedItem.personalAgreement.$touch()"
                :error-messages="personalAgreementErrors"
                label="Я согласен на передачу своих персональных данных Сетевой организации и Гарантирующему поставщику"
              />
            </div>
          </div>
          <div v-else>
            <v-card color="#FFCDD2">
              <v-card-text
                >Необходимо заполнить персональные данные клиента!</v-card-text
              >
              <v-card-actions>
                <router-link to="/profile">Заполнить сейчас</router-link>
              </v-card-actions>
            </v-card>
          </div>
          <v-card color="#FFCDD2" v-if="isDisabled">
            <v-card-text
              >Редактирование невозможно. Заявка уже принята к
              рассмотрению.</v-card-text
            >
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="Save"
          :disabled="isDisabled && loading"
        >
          {{ editMode ? 'Обновить' : 'Создать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import {
  DICTIONARY_TAG,
  DOCUMENT_TAG,
  GLOBAL_EVENT,
  USER_ROLE,
  CONNECTION,
  VALID_ERRORS
} from '../../../utils/mainConsts';

import { inputSaveEdit } from '../../../mixins/inputSaveEdit';

import { checkValid } from '../../../utils/helpers';

import itemSelector from '../../ui/ItemSelector2';

const {
  between,
  required,
  minLength,
  maxLength,
  numeric
} = require('vuelidate/lib/validators');

const PATH = process.env.VUE_APP_UPLOADIMAGE; // "http://localhost:3000/uploader";

const comboRequire = val => {
  return +val > 0;
};

export default {
  mixins: [inputSaveEdit], // Шаблонное сохранение и редактирование а также закрытие окна
  // Часть настроек миксина должны быть перезаписаны. см переменные самого миксина
  components: {
    'item-selector2': itemSelector
  },
  data: () => ({
    updateEvent: GLOBAL_EVENT.updateDocumentsList,
    tag: DOCUMENT_TAG.connection,
    rusTag: 'техприсоединение',
    title: 'Заявление на техприсоединение',
    thisUser: {
      partner: {
        orgType: 0
      }
    },
    editedItem: {
      type: 0,
      loadNature: 0,
      reliability: 0,
      reason: 0,
      method: 0,
      devNames: '',
      region: '',
      address: '',
      maxPower: '',
      powerOwened: '',
      pointsC: [],
      powerBefore: '',
      pointsB: [],
      voltage: '',
      pointsNum: '',
      voltage1: '',
      voltage2: '',
      voltage3: '',
      tempDates: '',
      dates: '',
      plans: '',
      offers: '',
      personalAgreement: false,
      partner: {},
      status: 0
    },
    hideForm: false,
    partnerModel: '',
    loadNature: CONNECTION.loadNature,
    reliability: CONNECTION.reliability,
    method: CONNECTION.method,
    reason: CONNECTION.reason,
    rules: [
      value =>
        !value ||
        value.size < 2000000 ||
        'Файл не должен превышать 2 мегабайта!'
    ],
    files: {}
  }),
  validations: {
    editedItem: {
      partner: {
        notEmpty(val) {
          if (val === undefined || Object.keys(val).length === 0) {
            return false;
          }
          return true;
        }
      },
      type: {
        between: between(1, 4)
      },
      devNames: {
        required,
        maxLength: maxLength(99)
      },
      region: {
        required,
        maxLength: maxLength(99)
      },
      address: {
        required,
        maxLength: maxLength(199)
      },
      maxPower: {
        required,
        maxLength: maxLength(9)
      },
      powerOwened: {
        maxLength: maxLength(9)
      },
      powerBefore: {
        maxLength: maxLength(9)
      },
      voltage: {
        maxLength: maxLength(9)
      },
      voltage1: {
        maxLength: maxLength(9)
      },
      voltage2: {
        maxLength: maxLength(9)
      },
      voltage3: {
        maxLength: maxLength(9)
      },
      level1: {
        maxLength: maxLength(9)
      },
      level2: {
        maxLength: maxLength(9)
      },
      level3: {
        maxLength: maxLength(9)
      },
      transNum: {
        maxLength: maxLength(19)
      },
      genNum: {
        maxLength: maxLength(19)
      },
      generator: {
        maxLength: maxLength(199)
      },
      armor: {
        maxLength: maxLength(199)
      },
      armorValue: {
        maxLength: maxLength(199)
      },
      loadNatureFull: {
        maxLength: maxLength(199)
      },
      tempDates: {
        maxLength: maxLength(99)
      },
      contractInfo: {
        maxLength: maxLength(99)
      },
      loadNature: {
        required: comboRequire
      },
      reliability: {
        required: comboRequire
      },
      dates: {
        maxLength: maxLength(199)
      },
      plans: {
        maxLength: maxLength(199)
      },
      offers: {
        maxLength: maxLength(199)
      },
      reason: {
        required: comboRequire
      },
      method: {
        required: comboRequire
      },
      personalAgreement: {
        checked(val) {
          return val;
        }
      }
    }
  },

  computed: {
    isDisabled() {
      if (this.editedItem.status === 0 || this.role >= 5) {
        return false;
      }
      return true;
    },
    partnerErrors() {
      const errors = [];
      if (!this.$v.editedItem.partner.$dirty) return errors;
      !this.$v.editedItem.partner.notEmpty &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    typeErrors() {
      return false;
    },
    devNamesErrors() {
      const errors = [];
      if (!this.$v.editedItem.devNames.$dirty) return errors;
      !this.$v.editedItem.devNames.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      !this.$v.editedItem.devNames.required &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    role: function() {
      return this.$store.state.role;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.editedItem.region.$dirty) return errors;
      !this.$v.editedItem.region.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      !this.$v.editedItem.region.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editedItem.address.$dirty) return errors;
      !this.$v.editedItem.address.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      !this.$v.editedItem.address.required &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    maxPowerErrors() {
      const errors = [];
      if (!this.$v.editedItem.maxPower.$dirty) return errors;
      !this.$v.editedItem.maxPower.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      !this.$v.editedItem.maxPower.required &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    powerOwenedErrors() {
      const errors = [];
      if (!this.$v.editedItem.powerOwened.$dirty) return errors;
      !this.$v.editedItem.powerOwened.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    powerBeforeErrors() {
      const errors = [];
      if (!this.$v.editedItem.powerBefore.$dirty) return errors;
      !this.$v.editedItem.powerBefore.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    voltageErrors() {
      const errors = [];
      if (!this.$v.editedItem.voltage.$dirty) return errors;
      !this.$v.editedItem.voltage.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    voltage1Errors() {
      const errors = [];
      if (!this.$v.editedItem.voltage1.$dirty) return errors;
      !this.$v.editedItem.voltage1.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    voltage2Errors() {
      const errors = [];
      if (!this.$v.editedItem.voltage2.$dirty) return errors;
      !this.$v.editedItem.voltage2.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    voltage3Errors() {
      const errors = [];
      if (!this.$v.editedItem.voltage3.$dirty) return errors;
      !this.$v.editedItem.voltage3.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    level1Errors() {
      const errors = [];
      if (!this.$v.editedItem.level1.$dirty) return errors;
      !this.$v.editedItem.level1.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    level2Errors() {
      const errors = [];
      if (!this.$v.editedItem.level2.$dirty) return errors;
      !this.$v.editedItem.level2.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    level3Errors() {
      const errors = [];
      if (!this.$v.editedItem.level3.$dirty) return errors;
      !this.$v.editedItem.level3.maxLength &&
        errors.push(VALID_ERRORS.maxLength(19));
      return errors;
    },
    transNumErrors() {
      const errors = [];
      if (!this.$v.editedItem.transNum.$dirty) return errors;
      !this.$v.editedItem.transNum.maxLength &&
        errors.push(VALID_ERRORS.maxLength(19));
      return errors;
    },
    generatorErrors() {
      const errors = [];
      if (!this.$v.editedItem.generator.$dirty) return errors;
      !this.$v.editedItem.generator.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    armorErrors() {
      const errors = [];
      if (!this.$v.editedItem.armor.$dirty) return errors;
      !this.$v.editedItem.armor.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    armorValueErrors() {
      const errors = [];
      if (!this.$v.editedItem.armorValue.$dirty) return errors;
      !this.$v.editedItem.armorValue.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    loadNatureFullErrors() {
      const errors = [];
      if (!this.$v.editedItem.loadNatureFull.$dirty) return errors;
      !this.$v.editedItem.loadNatureFull.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    genNumErrors() {
      const errors = [];
      if (!this.$v.editedItem.genNum.$dirty) return errors;
      !this.$v.editedItem.genNum.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      return errors;
    },
    tempDatesErrors() {
      const errors = [];
      if (!this.$v.editedItem.tempDates.$dirty) return errors;
      !this.$v.editedItem.tempDates.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },
    contractInfoErrors() {
      const errors = [];
      if (!this.$v.editedItem.contractInfo.$dirty) return errors;
      !this.$v.editedItem.contractInfo.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },
    loadNatureErrors() {
      const errors = [];
      if (!this.$v.editedItem.loadNature.$dirty) return errors;
      !this.$v.editedItem.loadNature.required &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    reliabilityErrors() {
      const errors = [];
      if (!this.$v.editedItem.reliability.$dirty) return errors;
      !this.$v.editedItem.reliability.required &&
        errors.push(VALID_ERRORS.required);
      return errors;
    },
    datesErrors() {
      const errors = [];
      if (!this.$v.editedItem.dates.$dirty) return errors;
      !this.$v.editedItem.dates.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    plansErrors() {
      const errors = [];
      if (!this.$v.editedItem.plans.$dirty) return errors;
      !this.$v.editedItem.plans.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    offersErrors() {
      const errors = [];
      if (!this.$v.editedItem.offers.$dirty) return errors;
      !this.$v.editedItem.offers.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.editedItem.reason.$dirty) return errors;
      !this.$v.editedItem.reason.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    methodErrors() {
      const errors = [];
      if (!this.$v.editedItem.method.$dirty) return errors;
      !this.$v.editedItem.method.required && errors.push(VALID_ERRORS.required);
      return errors;
    },
    personalAgreementErrors() {
      const errors = [];
      if (!this.$v.editedItem.personalAgreement.$dirty) return errors;
      !this.$v.editedItem.personalAgreement.checked &&
        errors.push(VALID_ERRORS.personal);
      return errors;
    },
    // Узнать входит ли тип параметра в диапазон types
    toShow: function() {
      const vm = this;
      return function(...types) {
        return types.filter(t => t === this.editedItem.type).length > 0;
      };
    }
  },

  async mounted() {
    if (this.role < 5) {
      const id = this.$store.state.userId;
      this.thisUser = await this.$store.dispatch('uniFetch', {
        name: DICTIONARY_TAG.user,
        id
      });
      if (!this.thisUser || !this.thisUser.partner) {
        this.hideForm = true;
      } else {
        this.hideForm = false;
      }
    } else {
      this.hideForm = false;
    }
  },

  methods: {
    ...mapMutations(['setMessageData']),
    equal(a, ...nums) {
      nums.forEach(n => {
        if (+a === +n) {
          return true;
        }
      });
      return false;
    },
    isValid() {
      // console.log(this.$v);
      this.$v.$touch();
      // Это валидация формы.
      if (
        (+this.editedItem.type === 1 &&
          checkValid(
            this.$v.editedItem,
            'partner',
            'devNames',
            'region',
            'address',
            'reason',
            'maxPower',
            'voltage1',
            'method',
            'personalAgreement',
            'loadNatureFull',
            'tempDates',
            'contractInfo'
          )) ||
        (+this.editedItem.type === 2 &&
          checkValid(
            this.$v.editedItem,
            'partner',
            'devNames',
            'region',
            'address',
            'maxPower',
            'powerOwened',
            'powerBefore',
            'voltage1',
            'voltage2',
            'voltage3',
            'transNum',
            'genNum',
            'generator',
            'armor',
            'armorValue',
            'level1',
            'level2',
            'level3',
            'dates',
            'plans',
            'reason',
            'method',
            'personalAgreement',
            'loadNatureFull'
          )) ||
        (+this.editedItem.type === 3 &&
          checkValid(
            this.$v.editedItem,
            'partner',
            'devNames',
            'region',
            'address',
            'maxPower',
            'powerOwened',
            'powerBefore',
            'voltage1',
            'voltage2',
            'voltage3',
            'reliability',
            'offers',
            'dates',
            'plans',
            'reason',
            'method',
            'personalAgreement',
            'loadNatureFull'
          )) ||
        (+this.editedItem.type === 4 &&
          checkValid(
            this.$v.editedItem,
            'partner',
            'devNames',
            'region',
            'address',
            'maxPower',
            'powerOwened',
            'powerBefore',
            'voltage1',
            'voltage2',
            'voltage3',
            'reliability',
            'dates',
            'plans',
            'reason',
            'method',
            'personalAgreement',
            'loadNatureFull'
          ))
      ) {
        return true;
      }
      return false;
    },

    NullForm() {
      this.editedItem = {
        type: 0,
        loadNature: 0,
        reliability: 0,
        reason: 0,
        method: 0,
        devNames: '',
        region: '',
        address: '',
        maxPower: '',
        powerOwened: '',
        pointsC: [],
        powerBefore: '',
        pointsB: [],
        voltage: '',
        pointsNum: '',
        voltage1: '',
        voltage2: '',
        voltage3: '',
        tempDates: '',
        dates: '',
        plans: '',
        offers: '',
        personalAgreement: false,
        partner: {},
        status: 0
      };
      this.files = {};
    },

    async itemConvertion() {
      this.editedItem = {
        ...this.editedItem,
        loadNature: +this.editedItem.loadNature,
        reliability: +this.editedItem.reliability,
        reason: +this.editedItem.reason,
        method: +this.editedItem.method
      };

      delete this.editedItem.resultFiles;
      delete this.editedItem.bailee;
      delete this.editedItem.createdAt;

      // Преобразуем массив объектов с указателями на файлы в массив строк с их _id
      if (
        Array.isArray(this.editedItem.files) &&
        this.editedItem.files.length > 0
      ) {
        this.editedItem.files = this.editedItem.files.map(f =>
          f._id.toString()
        );
      } else {
        this.editedItem.files = [];
      }

      if (Object.keys(this.files).length > 0) {
        const dataBody = new FormData();
        // Подготовка данных для отправки. Multer ждет не более 10 штук каждого вида переменных ниже
        Object.keys(this.files).forEach(key => {
          // TODO: Тут если мультиселектор будет, то this.files[key] - это может быть массив, который в свою очередь
          // должен быть итерирован
          if (Array.isArray(this.files[key])) {
            // Если множественная загрузка файлов
            this.files[key].forEach(f => {
              dataBody.append('files', f);
              dataBody.append('description', key);
              dataBody.append('tag', key);
              dataBody.append('userId', this.$store.state.userId);
            });
          } else {
            // Если одиночная загрузка файлов
            dataBody.append('files', this.files[key]);
            dataBody.append('description', key);
            dataBody.append('tag', key);
            dataBody.append('userId', this.$store.state.userId);
          }
        });

        let images = []; // Сюда сохраним данные о вновь созданных изображениях в базе данных
        // Делаем запрос к API на принятие новых файлов

        try {
          const result = await fetch(PATH, {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + this.$store.state.token
            },
            body: dataBody
          });
          const resData = await result.json();

          if (resData.errors) {
            let errMessage = '';
            resData.errors.forEach(e => {
              errMessage += e + ' \n';
            });
            throw new Error(errMessage);
          } else {
            images = resData;
          }
        } catch (error) {
          this.setMessageData({
            message: error,
            type: 'snackbar'
          });
          return false;
        }

        // Тут уже имеем загруженные картинки.
        // Добавляем _id вновь загруженных картинок в массив файлов
        if (images && Array.isArray(images) && images.length > 0) {
          for (const image of images) {
            this.editedItem.files.push(image._id);
          }
          this.editedItem.createdBy = this.$store.state.userId;
        }
      }

      // Подгрузить также нужно еще и партнера
      // Либо партнер выбирается из списка, если позволяют права
      if (this.role < 5) {
        const id = this.$store.state.userId;
        const thisUser = await this.$store.dispatch('uniFetch', {
          name: DICTIONARY_TAG.user,
          id
        });
        if (thisUser && thisUser.partner) {
          this.editedItem.partner = thisUser.partner._id;
        } else {
          this.editedItem.partner = null;
        }
      } else {
        if (this.editedItem.partner) {
          this.editedItem.partner = this.editedItem.partner._id;
        }
      }

      return true;
    }
  }
};
</script>

<style scoped>
.form-area {
  background-color: rgb(233, 233, 233);
  position: relative;
  border-radius: 5px;
  border: solid 1px rgb(207, 207, 207);
  padding: 3px;
  margin: 20px 0px;
}

.form-area > header:first-of-type {
  color: black;
  position: absolute;
  font-size: 10px;
  background-color: white;
  top: -12px;
  left: 15px;
}
</style>
