var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-top":"10px"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Документы "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(_vm.data + '-item',{tag:"component"}),_c('confirm-window'),_c('connection-doc',{attrs:{"doc":_vm.selectedItem,"itemWindow":_vm.itemWindow},on:{"onClose":function($event){_vm.itemWindow = false}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"_id"},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.openItem(item)}}},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-file-document-outline")])],1),(_vm.role != 2)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.role >= 5)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteRequest(item)}}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.types[+item.type - 1])+" ")]}},{key:"item.partner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.partner.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt,"datetime"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statuses[+item.status].text)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }