<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn class="mx-2" fab dark small color="primary" v-on="on">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ editMode ? 'Редактирование' : 'Создание' }} {{ title }}а</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="editedItem.name"
            label="Наименование"
          ></v-text-field>
          <item-selector
            tag="nomenclature"
            title="Номенклатура"
            @selected="nomenclature = $event"
          />
          <item-selector
            tag="donor"
            title="Донор"
            @selected="donorSelected($event)"
          />
          <v-select
            :items="condition"
            v-model="editedItem.condition"
            label="Состояние"
          ></v-select>
          <v-text-field v-model="editedItem.mark" label="Марка"></v-text-field>
          <v-text-field
            v-model="editedItem.model"
            label="Модель"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.bodyNumber"
            label="Номер кузова"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.engine"
            label="Номер двигателя"
          ></v-text-field>
          <v-text-field v-model="editedItem.year" label="Год"></v-text-field>
          <v-text-field
            v-model="editedItem.manufacturer"
            label="Производитель"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.oemNumber"
            label="OEM номер"
          ></v-text-field>
          <v-text-field v-model="editedItem.color" label="Цвет"></v-text-field>
          <v-select
            :items="xAlign"
            v-model="editedItem.x"
            label="Перед/Зад"
          ></v-select>
          <v-select
            :items="yAlign"
            v-model="editedItem.y"
            label="Лево/Право"
          ></v-select>
          <v-select
            :items="zAlign"
            v-model="editedItem.z"
            label="Верх/Низ"
          ></v-select>
          <v-text-field v-model="editedItem.price" label="Цена"></v-text-field>
          <item-selector
            tag="image"
            view="image"
            title="Изображение"
            :toSelect="editedItem.image"
            @selected="images = $event"
          />
          <v-text-field
            v-model="editedItem.comment"
            label="Комментарий"
          ></v-text-field>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Close">Закрыть</v-btn>
        <v-btn color="blue darken-1" text @click="Save">
          {{ editMode ? 'Обновить' : 'Создать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DICTIONARY_TAG,
  DOCUMENT_TAG,
  GLOBAL_EVENT
} from '../../../utils/mainConsts';

import { inputSaveEdit } from '../../../mixins/inputSaveEdit';

import itemSelector from '../../ui/ItemSelector';

export default {
  mixins: [inputSaveEdit], // Шаблонное сохранение и редактирование а также закрытие окна
  // Часть настроек миксина должны быть перезаписаны. см переменные самого миксина
  components: {
    'item-selector': itemSelector
  },
  data: () => ({
    updateEvent: GLOBAL_EVENT.updateDocumentsList,
    tag: DOCUMENT_TAG.supply,
    rusTag: 'запчасть',
    title: 'Запчасть',
    editedItem: {
      condition: 'Контрактная',
      x: 'Неприменимо',
      y: 'Неприменимо',
      z: 'Неприменимо',
      mark: 'Универсальная',
      model: 'Универсальная',
      bodyNumber: 'Универсальная',
      engine: 'Универсальная',
      year: '',
      comment: ''
    },
    donor: '',
    images: [],
    nomenclature: '',
    xAlign: ['Перед', 'Зад', 'Неприменимо'],
    yAlign: ['Лево', 'Право', 'Неприменимо'],
    zAlign: ['Верх', 'Низ', 'Неприменимо'],
    condition: ['Новая', 'Контрактная', 'Б/у']
  }),
  validations: {},

  methods: {
    async donorSelected(donor) {
      this.donor = donor;
      const loadedItem = await this.$store.dispatch('uniFetch', {
        name: DICTIONARY_TAG.donor,
        id: donor.toString()
      });
      // Автоматическое подставление значений из справочника Донор

      this.editedItem.mark = loadedItem.mark;
      this.editedItem.model = loadedItem.model;
      this.editedItem.bodyNumber = loadedItem.bodyNumber;
      this.editedItem.engine = loadedItem.engine;
      this.editedItem.year = loadedItem.year;
    },
    async itemConvertion() {
      // Некоторое преобразование перед сохранением
      // console.log('before:', this.editedItem);
      if (
        this.nomenclature &&
        Array.isArray(this.nomenclature) &&
        this.nomenclature.length > 0
      ) {
        this.editedItem.nomenclature = this.nomenclature[0];
      } else if (this.nomenclature) {
        this.editedItem.nomenclature = this.nomenclature;
      }

      if (this.donor && Array.isArray(this.donor) && this.donor.length > 0) {
        this.editedItem.donor = this.donor[0];
      } else if (this.editedItem.donor) {
        this.editedItem.donor = this.donor;
      }
      // console.log('Converter get images:', this.images);
      if (this.images && Array.isArray(this.images) && this.images.length > 0) {
        this.editedItem.image = this.images;
      } else if (this.images) {
        this.editedItem.image = [this.images];
      }
      // console.log('After convertion:', this.editedItem);
      return true;
    }
  }
};
</script>

<style></style>
