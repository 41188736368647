<template>
  <div>
    <div class="form-header">
      <div style="text-transform: uppercase">Заявка</div>
      юридического лица (индивидуального предпринимателя), физического лица на
      присоединение по одному источнику электроснабжения энергопринимающих
      устройств с максимальной мощностью до 150 кВт включительно
    </div>
    <div class="form-body">
      <p>
        1. Наименование заявителя: <b>{{ doc.partner.name }}</b>
      </p>
      <div v-if="doc.partner.orgType === 0">
        <p>
          2. Номер записи в Едином государственном реестре юридических лиц
          (номер записи в Едином государственном реестре индивидуальных
          предпринимателей) и дата ее внесения в реестр
          <b>{{ doc.partner.ogrn }}</b> от
          <b>{{ doc.partner.creationDate }}</b>
        </p>
      </div>
      <div v-else-if="doc.partner.orgType === 1">
        <p>
          2. Паспортные данные серия, номер
          <b>{{ doc.partner.passportNum }}</b> выдан (кем, когда)
          <b>{{ doc.partner.passportOrg }}</b>
        </p>
      </div>
      <p>
        3. Место нахождения заявителя, в том числе фактический адрес
        <b>{{ doc.partner.address }}</b>
      </p>
      <p>
        4. В связи с
        <b>{{ reason[(+doc.reason || 1) - 1].text }}</b> просит осуществить
        технологическое присоединение <b>{{ doc.devNames }}</b> расположенных
        <b>{{ doc.address }}</b>
      </p>
      <p>
        5. Максимальная мощность энергопринимающих устройств (присоединяемых и
        ранее присоединенных) <b>{{ doc.maxPower }}</b> составляет кВт при
        напряжении <b>{{ doc.voltage1 }}</b> кВ
      </p>
      <p>
        а) максимальная мощность присоединяемых энергопринимающих устройств
        составляет <b>{{ doc.powerOwened }}</b> кВт при напряжении
        <b>{{ doc.voltage2 }}</b> кВ
      </p>
      <p>
        б) максимальная мощность ранее присоединенных энергопринимающих
        устройств составляет <b>{{ doc.powerBefore }}</b> кВт при напряжении
        <b>{{ doc.voltage3 }}</b> кВ
      </p>
      <p>
        6. Заявляемая категория надежности энергопринимающих устройств –
        <b>{{ reliability[doc.reliability - 1].text }}</b> (по одному источнику
        электроснабжения энергопринимающих устройств).
      </p>
      <p>
        7. Характер нагрузки (вид экономической деятельности заявителя)
        <b>{{ doc.loadNatureFull }}</b>
      </p>
      <p>
        8. Сроки проектирования и поэтапного введения в эксплуатацию объекта (в
        том числе по этапам и очередям), планируемое поэтапное распределение
        максимальной мощности: <b>{{ doc.dates }}</b>
      </p>
      <p>
        9. Порядок расчета и условия рассрочки внесения платы за технологическое
        присоединение по договору осуществляются <b>{{ doc.offers }}</b>
      </p>
      <p>
        10. Гарантирующий поставщик (энергосбытовая организация), с которым
        планируется заключение договора энергоснабжения (купли-продажи
        электрической энергии (мощности):
        <b>{{ 'ПАО "Красноярскэнергосбыт"' }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import { CONNECTION } from "../../../utils/mainConsts";

export default {
  props: ["doc"],
  data: () => ({
    reason: CONNECTION.reason,
    loadNature: CONNECTION.loadNature,
    status: CONNECTION.status,
    reliability: CONNECTION.reliability,
  }),
};
</script>

<style scoped>
.form-header {
  padding: 20px 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.form-body {
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  color: black;
}
</style>
