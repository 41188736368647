<template>
  <div>
    <div class="form-header">
      <div style="text-transform: uppercase">Заявка</div>
      юридического лица (индивидуального предпринимателя), физического лица на
      временное присоединение энергопринимающих устройств
    </div>
    <div class="form-body">
      <p>
        1. Наименование заявителя: <b>{{ doc.partner.name }}</b>
      </p>
      <div v-if="doc.partner.orgType === 0">
        <p>
          2. Номер записи в Едином государственном реестре юридических лиц
          (номер записи в Едином государственном реестре индивидуальных
          предпринимателей) и дата ее внесения в реестр
          <b>{{ doc.partner.ogrn }}</b> от
          <b>{{ doc.partner.creationDate }}</b>
        </p>
      </div>
      <div v-else-if="doc.partner.orgType === 1">
        <p>
          2. Паспортные данные серия, номер
          <b>{{ doc.partner.passportNum }}</b> выдан (кем, когда)
          <b>{{ doc.partner.passportOrg }}</b>
        </p>
      </div>
      <p>
        3. Место нахождения заявителя, в том числе фактический адрес
        <b>{{ doc.partner.address }}</b>
      </p>
      <p>
        4. В связи с
        <b>{{ reason[(+doc.reason || 1) - 1].text }}</b> просит осуществить
        технологическое присоединение <b>{{ doc.devNames }}</b> расположенных
        <b>{{ doc.address }}</b>
      </p>
      <p>
        5. Максимальная мощность энергопринимающих устройств
        <b>{{ doc.maxPower }}</b> составляет кВт при напряжении
        <b>{{ doc.voltage1 }}</b> кВ
      </p>
      <p>
        6. Характер нагрузки (вид экономической деятельности заявителя)
        <b>{{ doc.loadNatureFull }}</b>
      </p>
      <p>
        7. Срок электроснабжения по временной схеме
        <b>{{ doc.tempDates }}</b>
      </p>
      <p>
        8. Реквизиты договора на технологическое присоединение:
        <b>{{ doc.contractInfo }}</b>
      </p>
      <p>
        9. Гарантирующий поставщик (энергосбытовая организация), с которым
        планируется заключение договора энергоснабжения (купли-продажи
        электрической энергии (мощности):
        <b>{{ 'ПАО "Красноярскэнергосбыт"' }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import { CONNECTION } from "../../../utils/mainConsts";

export default {
  props: ["doc"],
  data: () => ({
    reason: CONNECTION.reason,
    loadNature: CONNECTION.loadNature,
    status: CONNECTION.status,
    reliability: CONNECTION.reliability,
  }),
};
</script>

<style scoped>
.form-header {
  padding: 20px 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.form-body {
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  color: black;
}
</style>
