<template>
  <div>
    <component
      :is="mode"
      :data="payload"
      @goToDocument="goToDocument($event)"
    ></component>
  </div>
</template>

<script>
import ListComponent from '../components/documents/List';
import MenuComponent from '../components/documents/Menu';
import { mapGetters } from 'vuex';

export default {
  name: 'document',
  components: {
    'list-component': ListComponent,
    'menu-component': MenuComponent
  },
  data: () => ({
    mode: 'menu-component',
    payload: {}
  }),
  computed: {
    ...mapGetters(['documentsList'])
  },
  mounted() {
    this.showMenu();
  },
  methods: {
    // Вывод конкретного раздела по его тегу
    goToDocument(tag) {
      this.payload = tag;
      this.mode = 'list-component';
    },
    // Вывод списка всех разделов
    showMenu() {
      this.payload = this.documentsList;
      this.mode = 'menu-component';
    }
  }
};
</script>

<style></style>
