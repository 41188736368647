<template>
  <div style="padding-top: 10px">
    <v-card>
      <v-card-title>
        Документы
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <component :is="data + '-item'" />
      <confirm-window />
      <connection-doc
        :doc="selectedItem"
        :itemWindow="itemWindow"
        @onClose="itemWindow = false"
      />
      <v-data-table
        dense
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        item-key="_id"
        class="elevation-1"
        @click:row="rowClick"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn icon class="mx-0" @click.prevent="openItem(item)">
            <v-icon color="teal">mdi-file-document-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="role != 2"
            icon
            class="mx-0"
            @click.prevent="editItem(item)"
          >
            <v-icon color="teal">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="role >= 5"
            icon
            class="mx-0"
            @click.prevent="deleteRequest(item)"
          >
            <v-icon color="pink">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.type="{ item }">
          {{ types[+item.type - 1] }}
        </template>
        <template v-slot:item.partner="{ item }">
          {{ item.partner.name }}
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.createdAt | date("datetime") }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ statuses[+item.status].text }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ConfirmWindow from "../ConfirmWindow";
import SupplyInput from "../documents/inputs/Supply";
import ConnectionInput from "../documents/inputs/Connection";
import ConnectionDocument from "../ui/ConnectionDocument";

import { mapMutations, mapState } from "vuex";
import {
  DOCUMENT_TAG,
  CONFIRM_ACTION,
  GLOBAL_EVENT,
  ITEM_TYPE,
  CONNECTION,
} from "../../utils/mainConsts";

export default {
  name: "documents-list",
  components: {
    "confirm-window": ConfirmWindow,
    "supply-item": SupplyInput,
    "connection-item": ConnectionInput,
    "connection-doc": ConnectionDocument,
  },
  props: {
    // Название справочника. От него будет зависить какие методы будут вызываться
    data: {
      type: String,
      default: DOCUMENT_TAG.connection,
    },
  },
  data: () => ({
    listType: ITEM_TYPE.document,
    search: "",
    selected: [],
    payloadItems: [],
    payloadHeaders: [],
    items: [],
    headers: [],
    showMenu: false,
    itemWindow: false,
    selectedItem: {},
    types: CONNECTION.typeShort,
    statuses: CONNECTION.status,
  }),
  watch: {
    payloadItems(value) {
      this.items = value;
    },
    payloadHeaders(value) {
      this.headers = value;
    },
  },
  computed: {
    ...mapState(["serverURI"]),
    role() {
      return this.$store.state.role;
    },
  },
  created() {
    this.update(this.data);
  },
  mounted() {
    // получено событие на обновление списка (перезагрузка из базы данных)
    this.$bus.$on(GLOBAL_EVENT.updateDocumentsList, (tag) => {
      this.update(tag);
    });

    // получено событие от подтверждающего окна на удаление элемента
    this.$bus.$on(GLOBAL_EVENT.confirmDelete, async (item, itemType) => {
      if (itemType !== this.listType) {
        // Событие пришло не для этого листа
        return;
      }
      try {
        await this.$store.dispatch("documentDelete", {
          name: this.tag,
          itme: item,
        });
        this.update(this.data);
        this.setMessageData({
          message: `Данные ${item.name} успешно удалено`,
          type: "snackbar",
        });
      } catch (e) {
        console.log(e);
        this.setMessageData({
          message: "При удалении произошла ошибка",
          type: "snackbar",
        });
      }
    });
  },
  methods: {
    ...mapMutations(["setMessageData"]),
    rowClick(value) {
      this.openItem(value);
    },
    // Проверяет если изображений много, то в список попадет только первое.
    // Если одно, то оно и попадет
    imageUrl: (item) => {
      return Array.isArray(item.image) ? item.image[0].fileName : item.fileName;
    },

    async update(tag) {
      const res = await this.$store.dispatch("uniFetchAll", {
        name: this.data,
      });

      if (res && Array.isArray(res) && res.length > 0) {
        this.payloadItems = res.map((el) => {
          return {
            ...el,
            id: el._id,
            actions: {},
          };
        });

        this.payloadHeaders = [
          ...this.$store.getters.allHeaders(this.data),
          { text: "", value: "actions" },
        ];
      }
    },
    openItem(item) {
      this.itemWindow = true;
      this.selectedItem = item;
    },

    editItem(item) {
      this.$bus.$emit(`${this.data}OpenItem`, {
        editMode: true,
        item,
      });
    },

    deleteRequest(item) {
      this.$bus.$emit(GLOBAL_EVENT.openConfirmWindow, {
        item,
        itemType: ITEM_TYPE.document,
        message: `Действительно хотите удалить ${item.name}?`,
        action: CONFIRM_ACTION.delete,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr {
  cursor: pointer;
}
</style>
